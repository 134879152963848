import Vue from 'vue'
import VueRouter from 'vue-router'
import mainLayout from "@/components/mainLayout"
import customerlist from "./modules/customerlist"
import modify from "./modules/modify"
import channellist from "./modules/channellist"

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'hash',
  routes: [
    {
      name: '登录',
      path: '/login',
      component: () => import("@/pages/login/login.vue")
    },
    {
      path: '/',
      meta: { title: '平台系统' },
      component: mainLayout,
      children: [
        ...customerlist,
        modify,
        channellist
      ]
    },
  ]
})

// const router = new VueRouter({
//     mode: 'history',
//     base: process.env.BASE_URL,
//     routes
// })

router.beforeEach((to, from, next) => {
  window.document.title = "平台系统";
  next();
})
export default router
