<template>
        <Layout :style="'min-height:'+ innerHeight + 'px'">
            <Header>
              <div class="title_nav_box">
                <div class="logo_box">
<!--                  <img src="../../assets/image/logo.png" alt="">-->
                </div>
                <div class="right_box">
                  <!-- <div><Input @on-change="inputchange" v-model="searchContent" placeholder="请输入" style="width: auto"><Icon type="ios-search" slot="suffix" /></Input></div> -->
                  <!-- <div><Button style="background: #FFD878;color: #000;border-color: none;" class="ml-5">搜索</Button></div> -->
                  <div class="layout-nav ml-20">
                    <MenuItem name="logout" style="display: flex;align-items: center">
                      <div class="bg-circle">{{ userInfo.nickname?userInfo.nickname.substring(0,1):'' }}</div><span style="color:#000;">{{ userInfo.nickname }}</span>
                    </MenuItem>
                    <div class="show-menu">
                      <div @click="logout">退出登录</div>
                    </div>
                  </div>
                </div>
              </div>
            </Header>
            <Layout style="z-index:1">
                <Sider hide-trigger :style="{background: '#fff'}">
                  <Menu :active-name="String($route.name)" ref="menu" theme="light" width="auto">
                    <div>
                      <div v-for="(item,index) in muen" :key="index">
                        <div v-if="item.children.length>0">
                          <Submenu :name="item.unique_auth" >
                            <template slot="title" style="display: flex;align-items: center">
                              <!-- <Icon :type="item.icon" size="25"/> -->
                              {{ item.title }}
                            </template>
                            <MenuItem  v-for="(child,idx) in item.children" :key="idx" :name="child.unique_auth" :to="child.src">
                              <div>
                                {{ child.title }}
                              </div>
                            </MenuItem>
                          </Submenu>
                        </div>
                        <div v-else>
                          <MenuItem :name="item.unique_auth" :to="item.src" style="display: flex;align-items: center">
                            <!-- <Icon :type="item.icon" size="25"/> -->
                            <div>{{ item.title }}</div>
                          </MenuItem>
                        </div>
                      </div>
                    </div>
                  </Menu>
                </Sider>
                <Layout :style="{padding: '20px'}">
                    <Content :style="{ background: '#fff'}">
                        <router-view></router-view>
                    </Content>
                </Layout>
            </Layout>
        </Layout>
</template>

<script>
import {removeCookies} from '@/utils/helper'
  export default {
    data(){
      return{
        innerHeight:0,
        searchContent:''
      }
    },
    computed: {
      muen(){
        return this.$store.state.menus
      },
      userInfo(){
        return this.$store.state.userInfo
      },
      auth(){
        return this.$store.state.auth
      }
    },
    created(){
      this.innerHeight = window.innerHeight - 2;
    },
    methods:{
      logout() {
        localStorage.clear()
        removeCookies('token')
        this.$router.push({path: '/login'})
      },
      inputchange(){
        this.$store.commit('setSearch', this.searchContent)
      },
    }
  }
</script>

<style scoped>
.layout{
    border: 1px solid #d7dde4;
    background: #f5f7f9;
    position: relative;
    border-radius: 4px;
    overflow: hidden;
}
.layout-logo{
    width: 100px;
    height: 30px;
    background: #5b6270;
    border-radius: 3px;
    float: left;
    position: relative;
    top: 15px;
    left: 20px;
}
.layout-nav{
    /* width: 420px; */
    margin: 0 auto;
    margin-right: 20px;
}
.ivu-layout-header{
  background: #FFFFFF;
  box-shadow: #F3EEE2 0px 3px 8px;
  z-index: 9;
}
.ivu-layout-sider{
  box-shadow: #F3EEE2 0px 1px 4px;
}
.ivu-layout-content{
  position: relative;
  border-radius: 8px;
}
.ivu-menu-vertical.ivu-menu-light:after{
  background: none;
}
.ivu-menu-light.ivu-menu-vertical .ivu-menu-item-active:not(.ivu-menu-submenu){
  color: #000;
  background: #FFD878;
  justify-content: center;
}
.ivu-menu-vertical .ivu-menu-item, .ivu-menu-vertical .ivu-menu-submenu-title{
  color: #000;
  justify-content: center;
}
.ivu-menu-light.ivu-menu-vertical .ivu-menu-item-active:not(.ivu-menu-submenu) i{
  color: #0893FF;
}
.ivu-menu-light.ivu-menu-vertical .ivu-menu-item-active:not(.ivu-menu-submenu):after{
  background: unset;
}
.ivu-menu-light{
  margin: 20px 0;
}
.title_nav_box{
  width: 100%;
  /* position: relative; */
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.logo_box{
  position: absolute;
  top: 0;
  left: -50px;
  margin-top: 8px;
}
.logo_box img{
  width: 400px;
}
.right_box{
  display: flex;
  align-items: center;
}
.layout-nav {
  /* width: 200px; */
  margin: 0 auto;
  margin-right: 20px;
  position: relative;
}
.layout-nav:hover .show-menu {
  display: inline-block;
}
.bg-circle {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: #FFD878;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
}
.show-menu {
  display: none;
  position: absolute;
  width: 100px;
  height: 60px;
  /* right: 30px; */
  top: 60px;
  background-color: #fff;
  border: 1px solid #ebeef5;
  border-radius: 4px;
  box-shadow: 0 2px 12px 0 #ebeef5;
  text-align: center;
}
.show-menu div {
  cursor: pointer;
}
.show-menu div:hover{
  background: #d7dde4;
}
.ml-5{
  margin-left: 5px;
}
.ml-20{
  margin-left: 20px;
}
</style>